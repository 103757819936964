/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { faqs } from '../constants/faqs';
import {
  Exports,
  Imports,
  Local,
  Over3000SuccessfulShipments,
  ReliableCustomerSupport,
  ShopifyIcon,
  TrustedBySmallBusinesses
} from '../components/new-home-page/asset-constants';
import GetQuotesForm from '../components/get-quotes-form';
import NewFooter from '../components/new-footer';
import Carousel from '../components/new-home-page/carousel';
import { Link, useHistory } from 'react-router-dom';
import {
  ApiDocImg,
  PluginImg2,
  ShopnShipIcon,
  WhiteArrow
} from '../constants/asset-constants';
import getShippingLocationInfo from '../utilities/get-shipping-location';
import getLocationTexts from '../utilities/get-location-texts';
import { Helmet } from 'react-helmet';
import { allCountriesJSONData, ourCountries } from '../constants/countries';
import { Box, Grid } from '@mui/material';
import { lagosCities } from '../constants/cities';
import allowURL, { domestic } from '../constants/seo-array';
import GetMobileApp from '../components/new-home-page/get-mobile-app';
import Testimonials from '../components/testimonials';
import FooterCta from '../components/new-home-page/footer-cta';
import FaqBlock from '../components/faq-block';
import Scroller from '../constants/animation';
import DropOffHubs from '../components/drop-off-hubs';
import HomeCarousel from '../components/new-home-page/home-carousel';
import { ContactForm } from '../components/contact-form';

const isMobile = window.innerWidth <= 508;

const NewHomePage = props => {
  const [getFaqs] = useState(faqs);
  const history = useHistory();
  const [success, setSuccess] = useState(0);
  const [failure, setFailure] = useState(0);
  const pageURL = window?.location?.pathname?.split('/');
  const shippingInfo = getShippingLocationInfo();
  const { senderLocation, receiverLocation } = getLocationTexts(
    shippingInfo,
    history,
    { success, failure, setSuccess, setFailure }
  );
  const openUrl = link => {
    history.push(`/${link}`);
  };

  const removeHyphens = word =>
    word
      ?.split('-')
      ?.map(
        subWord =>
          `${subWord.charAt().toUpperCase()}${subWord.substring(
            1,
            subWord.length
          )}`
      )
      .join(' ');

  const capitalizeWord = word =>
    `${word.charAt().toUpperCase()}${word
      .substring(1, word.length)
      .toLowerCase()}`;

  const isLocalDelivery = pageURL?.[1] === 'local-delivery';
  const lastWordFromURL = pageURL[pageURL.length - 1];
  const isWithinLocalDelivery = isLocalDelivery && domestic(lastWordFromURL);

  const showNigerianCities =
    isLocalDelivery || (senderLocation && domestic(senderLocation));

  const localDeliveryHeader = `Express Delivery Service Within ${capitalizeWord(
    lastWordFromURL
  )}`;

  const localDeliveryDescription = `Send items to ${
    lastWordFromURL === 'abuja'
      ? 'Wuse, Gwagwalada, Maitama'
      : lastWordFromURL === 'ibadan'
      ? 'Dugbe, Bodija, Agodi'
      : 'Yaba, Ikeja, VI, Lekki'
  } and other parts of ${capitalizeWord(
    lastWordFromURL
  )} for as low as N1000. We offer same-day delivery service requests for orders before 2 pm.`;

  const header = isWithinLocalDelivery
    ? localDeliveryHeader
    : isLocalDelivery
    ? `Ship your items within Nigeria with Topship`
    : pageURL?.[1] &&
      removeHyphens(senderLocation) &&
      removeHyphens(receiverLocation)
    ? `Ship from ${removeHyphens(
        senderLocation
      )} to any location in ${removeHyphens(receiverLocation)}`
    : `Ship from ${removeHyphens(senderLocation)} to any location in the world`;

  const description = isWithinLocalDelivery
    ? localDeliveryDescription
    : isLocalDelivery
    ? `Send your items within Lagos, Ibadan & Abuja with
      Topship. It takes 3 - 5 working days to deliver and you
      can be rest assured of the best service when you ship with
      us.`
    : ` Send items to your friends, family, or customers in 
      ${removeHyphens(receiverLocation)} with Topship. Delivery takes 
      ${
        removeHyphens(receiverLocation) === 'United States' ||
        removeHyphens(senderLocation) === 'United States'
          ? '5 - 7'
          : '3 - 5'
      } 
      working days and you can be rest assured of a stress-free
      experience. Request a quote to get started!`;

  const availableCountries = allCountriesJSONData.filter(
    country => country.code !== 'NG' && ourCountries.test(country.code)
  );

  const availableCities = lagosCities.filter(city =>
    isWithinLocalDelivery
      ? city.name.toLowerCase() !== lastWordFromURL?.toLowerCase()
      : senderLocation
      ? city.name.toLowerCase() !== senderLocation?.toLowerCase()
      : city !== 'Lagos'
  );

  // const downloadAppLink = 'https://onelink.to/jzcdu2';

  useEffect(() => {
    if (!allowURL(window.location.pathname)) {
      return history.push('/404');
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className='new-home-page'>
      <Helmet>
        <meta
          name='description'
          content='Enjoy seamless local and international shipping when you ship with Topship. With flexible pricing at your fingertips.'
        />
        <meta
          name='keywords'
          content='Topship, international shipping, express delivery, logistics delivery company, international delivery, local shipping, cheap international shipping companies, cargo delivery'
        />
        {isWithinLocalDelivery ? (
          <title>{localDeliveryHeader}</title>
        ) : pageURL?.[1] === 'lagos' ||
          pageURL?.[1] === 'abuja' ||
          pageURL?.[1] === 'ibadan' ? (
          <title>
            {`Express Delivery Service from ${removeHyphens(
              senderLocation
            )} to ${
              removeHyphens(receiverLocation) || 'any location in the world'
            }`}
          </title>
        ) : pageURL?.[1] === 'ng' ? (
          <title>
            {`International Shipping from Nigeria to ${
              removeHyphens(receiverLocation) || 'Anywhere in the World'
            }`}
          </title>
        ) : pageURL?.[1] === 'local-delivery' ? (
          <title>Local Delivery to any location in Nigeria</title>
        ) : (
          <title>
            Topship Africa: Local {'&'} International Delivery Service
          </title>
        )}

        <link
          rel='canonical'
          href={`${window.location.origin}${window.location.pathname}`}
        />
      </Helmet>

      {window.location.pathname === '/' && (
        <section className='new-home-page__flexible-pricing'>
          <div
            className='new-home-page__notice'
            style={{ cursor: 'pointer' }}
            onClick={() => window.location.replace('/plug-ins')}
          >
            <div className='new-home-page__notice__wrap'>
              <div className=''>
                <img
                  src={ShopifyIcon}
                  alt='shopping bag'
                  className='img-fluid'
                />
              </div>
              <div className='new-home-page__notice__text'>
                <span>
                  <b>NEW:</b> You can now automate deliveries on your Shopify
                  store. {isMobile && <br />}
                  <b>Click to get started.</b>
                </span>
              </div>
              <div className='new-home-page__notice__action'>
                <img src={WhiteArrow} alt='White Arrow' className='img-fluid' />
              </div>
            </div>
          </div>
          <HomeCarousel />
          <div className='new-partners'>
            <p>Our Partners</p>
            <Scroller />
          </div>
        </section>
      )}

      <section className='new-home-page__banner '>
        <div className='homepage-container'>
          <div className='cta'>
            <div className='cta-contents'>
              {pageURL &&
              (removeHyphens(senderLocation) ||
                removeHyphens(receiverLocation)) ? (
                <>
                  <h1>{header}</h1>

                  <p>{description}</p>
                </>
              ) : (
                <>
                  <h1>
                    Send and Receive items from your doorstep to any location in
                    the world
                  </h1>
                  <p>
                    Welcome to the Soft life! Use Topship to send items to your
                    customers, friends, and family in Lagos, Abuja, London,
                    Ghana, New York, Dubai and 200+ more cities. Say goodbye to
                    stressful shipping forever!
                  </p>
                </>
              )}
              <div className='banner-cta'>
                <div className='cta-items'>
                  <div className='bttn  cta-item'>
                    <a
                      href={`https://ship.topship.africa/signup`}
                      className='new-home-page__link-button bttn-lg bttn-yellow'
                    >
                      Start Shipping
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id={'request-quote'} tabIndex={1} className='quotes'>
            <GetQuotesForm shippingInfo={shippingInfo} />
          </div>
        </div>
      </section>
      <ContactForm serviceTitle={''} isHomeForm />

      <section
        style={{
          position: 'relative'
        }}
        className='new-home-page__banner-info'
      >
        <div
          style={{
            position: 'relative',
            zIndex: '1'
          }}
          className='homepage-container'
        >
          <div className='banner-info-item'>
            <div className='banner-info-items'>
              <div className='col-items'>
                <div className='banner-info-item-1'>
                  <p>Trusted by Small and Medium Businesses Worldwide</p>
                  <img
                    src={TrustedBySmallBusinesses}
                    alt='trusted by small businesses'
                  />
                </div>
                <div className='banner-info-item-2'>
                  <p>
                    Reliable and friendly customer support representatives that
                    care about you
                  </p>
                  <img
                    src={ReliableCustomerSupport}
                    alt='reliable customer support'
                  />
                </div>
              </div>
              <div className='col-items'>
                <div className='banner-info-item-3'>
                  <p>
                    Over 30,000 shipments successfully delivered across multiple
                    locations Worldwide
                  </p>
                  <img
                    src={Over3000SuccessfulShipments}
                    alt='reliable customer support'
                  />
                </div>
              </div>
            </div>
            <div className='services'>
              <h3>Our Services</h3>
              <div className='services-items'>
                <div onClick={() => openUrl('lagos')} className='services-item'>
                  <div className='small-image'>
                    <img src={Local} alt='local' />
                  </div>
                  <div className='text'>
                    <p className='bold-text'>Nationwide Delivery</p>
                    <p className='light-text'>
                      Doorstep delivery to any city in Nigeria. Pickups are
                      available in Lagos, Abuja & Ibadan.
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => openUrl('exports')}
                  className='services-item'
                >
                  <div className='small-image'>
                    <img src={Exports} alt='exports' />
                  </div>
                  <div className='text'>
                    <p className=' bold-text'>Exports</p>
                    <p className='light-text'>
                      International shipping from Nigeria to over 200+ cities
                      worldwide.
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => openUrl('imports')}
                  className='services-item'
                >
                  <div className='small-image'>
                    <img src={Imports} alt='imports' />
                  </div>
                  <div className='text'>
                    <p className=' bold-text'>Imports</p>
                    <p className='light-text'>
                      Ship small and large items from any location worldwide to
                      Nigeria. Express & Budget options are available.
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => openUrl('shop-&-ship')}
                  className='services-item'
                >
                  <div className='small-image'>
                    <img
                      style={{
                        width: '40px'
                      }}
                      src={ShopnShipIcon}
                      alt='exports'
                    />
                  </div>
                  <div className='text'>
                    <p className=' bold-text'>Shop & Ship</p>
                    <p className='light-text'>
                      Shop international stores and pay no custom fees when you
                      ship them to Nigeria
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => openUrl('frozen-foods')}
                  className='services-item'
                >
                  <div className='small-image'>
                    <img src={Exports} alt='exports' />
                  </div>
                  <div className='text'>
                    <p className=' bold-text'>
                      Import Frozen Foods Into Nigeria
                    </p>
                    <p className='light-text'>
                      Order meats, poultry and seafood from international
                      suppliers and receive them within <b>48 hours</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='new-home-page__shipping-experience'>
        <div className='homepage-container'>
          <div className='shipping-experience'>
            <h3
              style={{
                position: 'relative'
              }}
            >
              <span className='span-img'>Everything </span>
              you need for the best shipping experience
            </h3>
            <Carousel />
          </div>
        </div>
      </section>

      <section className='new-home-page__plugin'>
        <div className='plugin-content'>
          <div className='tag'>Designed for merchants</div>
          <h1 className='header'>Plug in</h1>
          <p>
            Say goodbye to booking customer deliveries manually. Compatible with
            WordPress, WooCommerce, Shopify, and more. No coding is required.
          </p>
          <a
            href='https://form.typeform.com/to/klclH8qF'
            target='_blank'
            rel='noreferrer'
          >
            <button className='learn-more'>Join Waitlist</button>
          </a>
        </div>

        <div className='image-container'>
          <img src={PluginImg2} alt='developer-img' />
        </div>
      </section>

      <section className='new-home-page__api-doc'>
        <div className='api-content'>
          <div className='tag'>Designed for developers</div>
          <h1 className='header'>API</h1>
          <p>
            Integrate global shipping into your app or website. Send custom
            branded shipment notifications to your customers.
            Developer-friendly.
          </p>
          <Link to='/api'>
            <button className='learn-more'>Learn more</button>
          </Link>
        </div>

        <div className='image-container'>
          <img src={ApiDocImg} alt='developer-img' />
        </div>
      </section>

      <Testimonials />
      <GetMobileApp />
      <FaqBlock questions={getFaqs} />

      {!showNigerianCities && (
        <section
          id='country-list'
          className='mt-10 new-home-page__country-list'
        >
          <div className='new-home-page__footer__cta__header mb-3'>
            <h2>Send items to 150+ cities</h2>
          </div>
          <p className='fs-3'>Fast, affordable, and great customer service!</p>
          <Grid
            rowSpacing={'20px'}
            style={{ margin: '20px 2% 20px 4%' }}
            container
          >
            {availableCountries.map(country => (
              <Grid key={country.code} item lg={3} md={3} sm={6} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    '& > img': { mr: 2, flexShrink: 0 },
                    '& > a': { color: 'inherit' }
                  }}
                >
                  <img
                    loading='lazy'
                    width='30'
                    height='20'
                    src={`https://flagcdn.com/w20/${country.code?.toLocaleLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country.code?.toLocaleLowerCase()}.png 2x`}
                    alt=''
                  />
                  <a href={`/ng/${country.code.toLowerCase()}`}>
                    {country.name}
                  </a>
                </Box>
              </Grid>
            ))}
          </Grid>
        </section>
      )}
      {showNigerianCities && (
        <section className='new-home-page__country-list'>
          <div className='new-home-page__footer__cta__header mb-3'>
            <h2>Send {'&'} Receive items across Nigeria</h2>
          </div>
          <p className='fs-3'>Fast, affordable, and great customer service!</p>
          <Grid
            rowSpacing={'20px'}
            style={{ margin: '20px 2% 20px 4%' }}
            container
          >
            {availableCities.map((city, cityIndex) => (
              <Grid key={cityIndex} item lg={3} md={3} sm={6} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    '& > img': { mr: 2, flexShrink: 0 },
                    '& > a': { color: 'inherit' }
                  }}
                >
                  <img
                    loading='lazy'
                    width='30'
                    height='20'
                    src={`https://flagcdn.com/w20/ng.png`}
                    srcSet={`https://flagcdn.com/w40/ng.png 2x`}
                    alt=''
                  />
                  <a
                    href={`/${
                      isWithinLocalDelivery
                        ? lastWordFromURL.toLowerCase()
                        : senderLocation?.toLowerCase() || 'lagos'
                    }${city.link.toLowerCase()}`}
                  >
                    {city.name}
                  </a>
                </Box>
              </Grid>
            ))}
          </Grid>
        </section>
      )}
      <DropOffHubs />
      <FooterCta heading='Create an account and start shipping in minutes' />
      <section className='new-home-page__footer'>
        <NewFooter />
      </section>
    </div>
  );
};

export default NewHomePage;
